export default function classifyURL(url) {
  let info = {
    class: 'unclassified'
  }
  const youtube_id = youtubeID(url);
  if(youtube_id !== false) {
    info.class = 'youtube';
    info.id = youtube_id
  }
  else if(url.includes('drive.google.com')) {
    info.class = 'googleDrive';
  }
  else if(isURL(url)) {
    info.class = 'url';
  }
  return info;
}

function youtubeID(url) {
  if(url.includes('youtube.com')) {
    let video_id = url.split('v=')[1];
    if(video_id === undefined)
      return false;
    video_id = video_id.split('&')[0];
    if(video_id === undefined || video_id.length !== 11)
       return false;
    return video_id;
  }
  // mobile link
  if(url.includes('youtu.be')) {
    let video_id = url.split('be/')[1];
    if(video_id === undefined || video_id.length !== 11)
       return false;
    return video_id;
  }
  return false;
}

// CHECKING IF THE STRING IS AN URL
function isURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}
