import React from 'react';
import axios from 'axios';
import keys from '../../keys';

import CoursesList from './PanelCoursesList';

class CoursesPanelComponent extends React.Component {
  state = {
    course: {
      courseName: '',
      courseAbout: ''
    },
    status: ''
  }
  handleChange = (e) => {
    this.setState({
      ...this.state,
      course: {
        ...this.state.course,
        [e.target.id]: e.target.value
      }
    });
  }
  handleSubmit = (e) => {
    this.setState({
      ...this.state,
      status: 'loading'
    });
    e.preventDefault();
    axios.post(keys.apiURL + '/courses/insert', {course: this.state.course}, {withCredentials: true}).then(res => {
      if(res.status === 200 && res.data === 'inserted') {
        this.setState({
          ...this.state,
          status: 'inserted'
        });
      }
      else {
        this.setState({
          ...this.state,
          status: 'failed'
        });
      }
    });
  }
  render() {
    if(this.state.status === 'inserted' || this.state.status === 'failed')
      setTimeout(() => window.location.reload(), 1500);

    return (
      <div>
        <CoursesList userName={this.props.user.info.userName} />
        <h5 className="mt-4">Add a new course</h5>
        <hr />
        <form onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-8">
              <label htmlFor="courseName">Course name</label>
              <input type="text" className="form-control" id="courseName" required onChange={this.handleChange} />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="courseAbout">About the course</label>
            <textarea className="form-control textarea" id="courseAbout" placeholder="" required onChange={this.handleChange}></textarea>
          </div>
          <button type="submit" className="btn btn-primary">create</button>
          {
            this.state.status === 'loading' &&
            <p className="mt-3">
              please wait...
            </p>
          }
          {
            this.state.status === 'inserted' &&
            <div className="alert alert-dismissible fade show alert-success mt-3" role="alert">
              Done! course added successfully.
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          }
          {
            this.state.status === 'failed' &&
            <div className="alert alert-dismissible fade show alert-danger mt-3" role="alert">
              Ops! something wrong happened.
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          }
        </form>
      </div>
    );
  }
}

export default CoursesPanelComponent;
