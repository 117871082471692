import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import keys from '../../keys';

import Loading from '../LoadingComponent';

class HomeComponennt extends React.Component {
  state = {
    courses: [],
    isLoading: true
  }

  componentDidMount() {
    this.getCoursesData();
  }

  getCoursesData = () => {
    axios.get(keys.apiURL + '/courses/popular').then(res => {
      this.setState({
        ...this.state,
        courses: res.data.courses,
        instructors: res.data.instructors,
        isLoading: false
      });
    });
  }

  render() {
    const courses = this.state.courses;
    const instructors = this.state.instructors;
    let Component;
    if(courses.length !== 0) {
      Component = (
        courses.map((course, i) => {
          return (
            <div key={course._id} className="p-1 col-12 col-md-6 col-lg-4 col-xl-3">
              <div className="card shadow">
                <div className="card-body">
                  <h5 className="card-title">{course.name}</h5>
                  <div className="body">
                    by: <Link className="no-decoration" to={'/' + instructors[i].userName}>{instructors[i].name}</Link>
                    <p>{course.about}</p>
                  </div>
                  <Link to={'/courses/' + course._id} className="btn btn-sm btn-outline-primary">Show</Link>
                </div>
              </div>
            </div>
          );
        })
      );
    }
    return (
      <div className="container">
        <h4>Popular courses</h4>
        <hr />
        {
          this.state.isLoading ?
          <Loading />
          :
          <div className="row m-0">
            { Component }
          </div>
        }
      </div>
    );
  }
}

export default HomeComponennt;
