import React from 'react';
import axios from 'axios';
import keys from '../../keys';

import SearchResults from './SearchResultsComponent';

class SearchComponent extends React.Component {
  state = {
    input: '',
    organizations: [],
    courses: [],
    users: []
  }
  handleChange(event) {
    this.setState({
      ...this.state,
      input: event.target.value
    });
    let str = event.target.value.replace(' ', '+');
    axios.get(keys.apiURL + '/search?input=' + str).then(data => {
      this.setState({
        ...this.state,
        organizations: data.data.organizations,
        courses: data.data.courses,
        users: data.data.users
      });
    });
  }
  render() {
    return (
      <div className="search">
        <div className="modal fade" id="searchModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <input className="form-control" type="text" value={this.state.input} onChange={(event) => this.handleChange(event)} placeholder="Search for someone or an organization..." />
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pt-2">
                <SearchResults input={this.state.input} organizations={this.state.organizations} courses={this.state.courses} users={this.state.users} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchComponent;
