import React from 'react';
import notFoundDrawing from './404.svg';

function NotFoundComponent() {
  return (
    <div className="container">
      <div className="d-flex justify-content-around">
        <img className="not-found" src={notFoundDrawing} alt="404" />
      </div>
      <h5 className="d-flex justify-content-around mt-3">this page is currently unavailable.</h5>
    </div>
  )
}

export default NotFoundComponent;
