const initialState = {
  isLoading: true,
  user: {
    isSignedIn: false,
    info: {}
  }
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'AUTH_WITH_COOKIE': {
        return {
          ...state,
          isLoading: false,
          user: {
            isSignedIn: Object.keys(action.user).length !== 0,
            info: action.user
          }
        }
      }
    default:
      return state
  }
}


export default reducer;
