import React from 'react';
import { connect } from 'react-redux';
import * as actions from './store/actions';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import axios from 'axios';

import keys from './keys';

// components
import Header from './components/header';
//import Welcome from './components/welcome';
import Home from './components/home';
import Loading from './components/LoadingComponent';
import Page from './components/PageComponent';
import CoursePage from './components/courses/CoursePageComponent';
import Search from './components/search/SearchComponent';
import Manage from './components/manage/ManageComponent';
import Footer from './components/FooterComponent';
import ScrollToTop from './components/ScrollToTop';

// BOOTSTRAP and index.css
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.props.authWithCookie();
  }
  render() {
    //const socket = io('http://localhost:3001/');
    return (
      <BrowserRouter>
        {
          this.props.isLoading ? <Loading /> :
          <div className="App">
            <Header user={this.props.user} />
            <main>
              <ScrollToTop/>  {/* scroll to top after each router page change */}
              <Switch>
                <Route exact path='/'>
                  { this.props.user.isSignedIn ? <Home user={this.props.user} /> : <Home user={this.props.user} /> }
                </Route>
                <Route path='/manage'>
                  { this.props.user.isSignedIn ? <Manage user={this.props.user} /> : <Redirect to="/" /> }
                </Route>
                <Route path='/courses/:id' render={(routeProps) => <CoursePage className="mp-10" routeProps={routeProps} user={this.props.user} /> } />
                <Route path='/:name' component={Page} />
              </Switch>
            </main>
            <Search />
            <Footer />
          </div>
        }
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    isLoading: state.isLoading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authWithCookie: () => dispatch(actions.authWithCookie())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
