import React from 'react';
import axios from 'axios';
import keys from '../../keys';

class PanelComponent extends React.Component {
  state = {
    section: {
      name: '',
      intro: ''
    },
    status: ''
  }
  handleChange = (e) => {
    this.setState({
      ...this.state,
      section: {
        ...this.state.section,
        [e.target.id]: e.target.value
      }
    });
  }
  handleSubmit = (e) => {
    this.setState({
      ...this.state,
      status: 'loading'
    });
    e.preventDefault();
    axios.post(keys.apiURL + '/courses/' + this.props.courseId + '/addsection',
     {courseId: this.props.courseId, section: this.state.section}, {withCredentials: true}).then(res => {
      if(res.status === 200 && res.data === 'added') {
        this.setState({
          ...this.state,
          status: 'added'
        });
      }
      else {
        this.setState({
          ...this.state,
          status: 'failed'
        });
      }
    });
  }
  render() {
    if(this.state.status === 'added')
      setTimeout(() => window.location.reload(), 1500);
    return (
      <div>
        <p>
          <button className="btn btn-primary btn-sm" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            + Add a new section
          </button>
        </p>
        <div className="collapse" id="collapseExample">
          <div className="card card-body">
            <form onSubmit={this.handleSubmit}>
              <div className="form-row">
                <div className="form-group col-md-8">
                  <label htmlFor="name">Section name</label>
                  <input type="text" className="form-control" id="name" required onChange={this.handleChange} />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="intro">Section introduction</label>
                <textarea className="form-control textarea-section" id="intro" placeholder="" required onChange={this.handleChange}></textarea>
              </div>
              <button type="submit" className="btn btn-sm btn-primary">Add</button>
              {
                this.state.status === 'loading' &&
                <p className="mt-3">
                  please wait...
                </p>
              }
              {
                this.state.status === 'added' &&
                <div className="alert alert-dismissible fade show alert-success mt-3" role="alert">
                  Done! section added successfully.
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              }
              {
                this.state.status === 'failed' &&
                <div className="alert alert-dismissible fade show alert-danger mt-3" role="alert">
                  Ops! something wrong happened.
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              }
            </form>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

export default PanelComponent;
