import React from "react";
import ClipLoader from "react-spinners/PuffLoader";

export default function LoadingComponent() {
  return (
    <div className="screen-height d-flex flex-row justify-content-center align-items-center">
      <ClipLoader loading size="120px" />
    </div>
  );
}
