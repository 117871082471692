import React from 'react';
import { Link } from 'react-router-dom';

function FooterComponent() {
  return(
    <footer>
      <div className="py-2">
        <p className="text-center m-0">Made with <span className="emoji">&#10084;</span></p>
        <div className="text-center">© 2020 <Link to="/" className="text-dark">leomango</Link>
        </div>
      </div>
    </footer>
  );
}

export default FooterComponent;
