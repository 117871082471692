import React from 'react';
import { Link } from 'react-router-dom';
import searchDrawing from './search.svg';

class SearchResultsComponent extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="list-group" id="list-tab" role="tablist">
            {
              this.props.input === '' && <img className="searchDrawing my-5" src={searchDrawing} alt="search for something..." />
            }
            {
              this.props.input !== '' &&
              this.props.organizations.length === 0 &&
              this.props.courses.length === 0 &&
              this.props.users.length === 0 &&
              <p>Try to be more specific...</p>
            }
            {
              this.props.organizations.length > 0 && <h6>organizations</h6>
            }
            {
              this.props.organizations.length > 0 &&
              this.props.organizations.map(organization => {return (
                <Link key={organization._id} to={'/' + organization.organizationName} className="list-group-item d-flex p-1 list-group-item-action">
                  <img className="icon p-2" src={organization.icon} alt="logo" />
                  <div className="d-flex flex-column">
                    <span>{organization.name}</span>
                    <span>{organization.organizationName}</span>
                  </div>
                </Link>
              )})
            }
          </div>
          <div className="list-group" id="list-tab" role="tablist">
            {
              this.props.courses.length > 0 && <h6 className="mt-3">courses</h6>
            }
            {
              this.props.courses.length > 0 &&
              this.props.courses.map(course => {return (
                <Link key={course._id} to={'/courses/' + course._id} className="list-group-item d-flex p-1 list-group-item-action">
                  <div className="d-flex flex-column p-3">
                    <span>{course.name}</span>
                  </div>
                </Link>
              )})
            }
          </div>
          <div className="list-group" id="list-tab" role="tablist">
            {
              this.props.users.length > 0 && <h6 className="mt-3">people</h6>
            }
            {
              this.props.users.length > 0 &&
              this.props.users.map(user => {return (
                <Link key={user._id} to={'/' + user.userName} className="list-group-item d-flex p-1 list-group-item-action">
                  <img className="icon p-2" src={user.googlePicture} alt="profile" />
                  <div className="d-flex flex-column">
                    <span>{user.name}</span>
                    <span>{user.userName}</span>
                  </div>
                </Link>
              )})
            }
          </div>
        </div>
      </div>
    );
  }
}

export default SearchResultsComponent;
