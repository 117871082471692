import React from 'react';

import SignedInComponent from './signedIn/SignedInComponent';
import SignedOutComponent from './signedOut/SignedOutComponent';
import SearchButton from '../search/SearchButtonComponent';

import { Link } from 'react-router-dom';

class HeaderComponent extends React.Component {
  render() {
    return (
      <header>
        <nav className="navbar justify-content-between navbar-expand fixed-top navbar-light px-3">
          <Link className="navbar-brand" to="/">leomango</Link>
          <SearchButton />
          <div className="sign-container">
            {
              this.props.user.isSignedIn ? <SignedInComponent user={this.props.user} /> : <SignedOutComponent />
            }
          </div>
        </nav>
      </header>
    );
  }
}

export default HeaderComponent;
