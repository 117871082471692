import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'

import keys from '../../../../keys';

class EditSectionModalComponent extends React.Component {
  state = {
    section: {
      name: this.props.section.name,
      intro: this.props.section.intro,
      body: this.props.section.body
    },
    status: ''
  }
  handleChange = (e) => {
    this.setState({
      ...this.state,
      section: {
        ...this.state.section,
        [e.target.id]: e.target.value
      }
    });
  }

  //  BODY PARAGRAPH CHANGE
  handleBodyParagraphChange = (e) => {
    const index = e.target.id.split("paragraph").pop();
    // 1. Make a shallow copy of the items
    let body = [...this.state.section.body];
    // 2. Make a shallow copy of the item you want to mutate
    let part = {...body[index]};
    // 3. Replace the property you're intested in
    part.paragraph = e.target.value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    body[index] = part;
    // 5. Set the state to our new copy
    this.setState({
      ...this.state,
      section: {
        ...this.state.section,
        body
      }
    });
  }
  // BODY URL CHANGE
  handleBodyURLChange = (e) => {
    const index = e.target.id.split("url").pop();
    // 1. Make a shallow copy of the items
    let body = [...this.state.section.body];
    // 2. Make a shallow copy of the item you want to mutate
    let part = {...body[index]};
    // 3. Replace the property you're intested in
    part.url = e.target.value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    body[index] = part;
    // 5. Set the state to our new copy
    this.setState({
      ...this.state,
      section: {
        ...this.state.section,
        body
      }
    });
  }
  handleSubmit = (e) => {
    this.setState({
      ...this.state,
      status: 'loading'
    });
    // removing all th empty parts of the section before sending it
    let bodyToSend = [];
    this.state.section.body.forEach(part => {
      if(part.url.replace(/(\r\n|\n|\r)/gm, "") !== '' || part.paragraph.replace(/(\r\n|\n|\r)/gm, "") !== '')
        bodyToSend.push(part);
    });
    const sectionToSend = {
      ...this.state.section,
      body: bodyToSend
    }
    axios.post(keys.apiURL + '/sections/' + this.props.section._id + '/edit',
     {section: sectionToSend, parentCourse: this.props.section.parentCourse }, {withCredentials: true}).then(res => {
      if(res.status === 200 && res.data === 'saved') {
        this.setState({
          ...this.state,
          status: 'saved'
        });
      }
      else {
        this.setState({
          ...this.state,
          status: 'failed'
        });
      }
    });
  }

  addPart = () => {
    const newBody = this.state.section.body.concat({url: '', paragraph: ''});
    this.setState({
      ...this.state,
      section: {
        ...this.state.section,
        body: newBody
      }
    });
  }

  render() {
    const body = this.state.section.body.map((part, i) => (
      <div key={i}>
        <hr/>
        <div className="form-group">
          <label htmlFor={'paragraph' + i}>part {i + 1} of the body</label>
          <input type="text" className="form-control" id={'paragraph' + i} value={this.state.section.body[i].paragraph} onChange={this.handleBodyParagraphChange} placeholder="Text" />
        </div>
        <div className="form-group">
          <input type="text" className="form-control" id={'url' + i} value={this.state.section.body[i].url} onChange={this.handleBodyURLChange} placeholder="URL" />
        </div>
      </div>
    ));

    if(this.state.status === 'saved')
      setTimeout(() => window.location.reload(), 1500);

    return (
      <div onClick={e => e.stopPropagation()}>
        <Modal {...this.props} size="lg" >
          <Modal.Body>
            <form onSubmit={this.handleSubmit}>
              <div className="form-row">
                <div className="form-group col-md-8">
                  <label htmlFor="name">Section name</label>
                  <input type="text" value={this.state.section.name} className="form-control" id="name" required onChange={this.handleChange} />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="intro">Section introduction</label>
                <textarea  value={this.state.section.intro} className="form-control textarea-section" id="intro" placeholder="" required onChange={this.handleChange}></textarea>
              </div>
              { body /* the body parts of the section */ }
              {/* ADD PART OF SECTION BUTTON */}
              <Button onClick={() => this.addPart()} size="sm">+ Add a new part</Button>
              {
                this.state.status === 'loading' &&
                <p className="mt-3">
                  please wait...
                </p>
              }
              {
                this.state.status === 'saved' &&
                <div className="alert alert-dismissible fade show alert-success mt-3" role="alert">
                  Done! changes saved successfully.
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              }
              {
                this.state.status === 'failed' &&
                <div className="alert alert-dismissible fade show alert-danger mt-3" role="alert">
                  Ops! something wrong happened.
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              }
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onHide} variant="secondary">Discard</Button>
            <Button onClick={() => this.handleSubmit()} type="submit">Save changes</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default EditSectionModalComponent;
