import axios from 'axios';
import keys from '../../keys';

export const asyncAuthWithCookie = (user) => {
  return {
    type: 'AUTH_WITH_COOKIE',
    user
  }
}

export const authWithCookie = () => {
  return dispatch => {
    axios.get(keys.apiURL + '/me', {withCredentials: true}).then(res => {
      if(res.data !== '') {
        dispatch(asyncAuthWithCookie(res.data));
      }
      else {
        dispatch(asyncAuthWithCookie({}));
      }
    });
  }
}
