import React from 'react';

import ProfileCol from './columns/ProfileColComponent';
import LoadingProfileCol from './columns/LoadingProfileColComponent';
import CoursesList from './columns/CoursesList';

class ProfileComponent extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3 px-1">
            { Object.keys(this.props.user).length !== 0 ? <ProfileCol user={this.props.user} /> : <LoadingProfileCol /> }
          </div>
          <div className="col-12 col-md-8 col-lg-9 px-1">
            <CoursesList userName={this.props.user.userName} />
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileComponent;
