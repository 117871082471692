import React from 'react';

import CoursesPanel from './coursesPanelComponent';
import ProfilePanel from './profilePanelComponent';

function ManageComponent(props) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-sm-4 mb-2 px-sm-2 px-2">
          <div className="list-group" id="list-tab" role="tablist">
            <a className="list-group-item list-group-item-action active" id="list-profile-list" data-toggle="list" href="#list-profile" role="tab" aria-controls="profile">profile setting</a>
            <a className="list-group-item list-group-item-action" id="list-courses-list" data-toggle="list" href="#list-courses" role="tab" aria-controls="courses">courses</a>
          </div>
        </div>
        <div className="col-lg-9 col-sm-8 px-sm-2 px-0">
          <div className="tab-content" id="nav-tabContent">
            <div className="board tab-pane fade show active" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">
              <ProfilePanel user={props.user} />
            </div>
            <div className="board tab-pane fade" id="list-courses" role="tabpanel" aria-labelledby="list-courses-list">
              <CoursesPanel user={props.user} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageComponent;
