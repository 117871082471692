import React from 'react';
import keys from '../../../keys';

export default function SignedOutComponent(props) {
  return (
    <div className="float-right">
      <a href={keys.apiURL + '/auth/google'} className="btn btn-sm btn-outline-danger my-2 my-sm-0" role="button">Google in</a>
    </div>
  );
}
