import React from 'react';
import axios from 'axios';
import keys from '../keys';

import Loading from './LoadingComponent';
import NotFound from './NotFoundComponent';
import OrganizationPage from './organization';
import UserPage from './profile';

class ProfileComponent extends React.Component {
  state = {
    visitedObjectName: this.props.match.params.name,
    visitedObject: {}
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps){
    if(prevProps.match.params.name !== this.props.match.params.name){
      this.getData();
    }
  }

  getData = () => {
    axios.get(keys.apiURL + '/' + this.props.match.params.name).then(res => {
      this.setState({
        ...this.state,
        notFound: false,
        visitedObject: res.data
      });
    }).catch(() => {
      this.setState({
        ...this.state,
        notFound: true
      });
    });
  }

  render() {
    let visitedObject = this.state.visitedObject;
    let Component = <Loading />
    if(this.state.notFound) {
      Component = <NotFound />
    }
    else if(Object.keys(visitedObject).length !== 0) {
      if(visitedObject.organizationName) {
        Component = <OrganizationPage organization={visitedObject} />
      }
      else if(visitedObject.userName) {
        Component = <UserPage user={visitedObject} />
      }
    }
    return (
      <div>
        { Component }
      </div>
    );
  }
}

export default ProfileComponent;
