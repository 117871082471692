import React from 'react';

class ProfileColComponent extends React.Component {
  render() {
    const user = this.props.user;
    const date = new Date(user.joinedAt);
    return (
      <div className="board profile-col py-4 mb-2">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-around">
              <img className="py-1" src={user.googlePicture} alt={user.name + '\'s profile picture'} />
            </div>
            <div className="col-12">
              <h1 className="text-center mb-3">{user.name}</h1>
            </div>
            <div className="col-12 about">
              <h6>@{user.userName}</h6>
              <span>joined { date.toLocaleDateString("en-US", {year: 'numeric', month: 'short'}) }</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileColComponent;
