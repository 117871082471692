import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import keys from '../../../keys';

import Loading from '../../LoadingComponent';

class CoursesList extends React.Component {
  state = {
    userName: this.props.userName,
    courses: [],
    notFound: true
  }

  componentDidMount() {
    this.getCoursesData();
  }

  getCoursesData = () => {
    axios.get(keys.apiURL + '/users/' + this.props.userName + '/courses').then(res => {
      this.setState({
        ...this.state,
        notFound: false,
        courses: res.data
      });
    }).catch(() => {
      this.setState({
        ...this.state,
        notFound: true
      });
    });
  }

  render() {
    let courses = this.state.courses;
    let Component = <Loading />
    if(courses.length !== 0) {
      Component = (
        courses.map(course => {
          return (
            <div key={course.id} className="card">
              <div className="card-body">
                <h5 className="card-title">{course.name}</h5>
                <p className="card-text">{course.about}</p>
                <Link to={'/courses/' + course.id} className="btn btn-sm btn-outline-primary">Show</Link>
              </div>
            </div>
          );
        })
      );
    }
    return (
      <div>
        {
           this.state.courses.length !== 0 &&
           <div className="board">
             { Component }
           </div>
        }
      </div>
    );
  }
}

export default CoursesList;
