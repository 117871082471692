import React from 'react';

import axios from 'axios';
import keys from '../../../keys';

import Card from 'react-bootstrap/Card';

class CourseHeader extends React.Component {
  state = {
    participated: false,
    imgSrc: this.props.course.picture
  };
  handleParticipate = () => {
    axios.post(keys.apiURL + '/courses/' + this.props.courseId + '/participate',
    'participate', {withCredentials: true}).then(res => {
      if(res.data === 'participated') {
        this.setState({
          ...this.state,
          participated: true
        });
      }
      else if(res.data === 'already') {
        this.setState({
          ...this.state,
          alreadyParticipated: true
        });
      }
      else if(res.data === 'login') {
        this.setState({
          ...this.state,
          login: true
        });
      }
    });
  }

  render() {
    //default image url
    const defImg = 'https://st2.depositphotos.com/1914485/12367/v/950/depositphotos_123673576-stock-illustration-education-background-with-icons.jpg';

    return (
      <div>
        <Card className="bg-dark text-white mb-2">
          <Card.Img className="course-header-img" src={this.state.imgSrc} onError={() => {this.setState({...this.state, imgSrc: defImg})}} alt="Card image" />
          <Card.ImgOverlay>
            <Card.Title>{this.props.course.name}</Card.Title>
            <Card.Text>{this.props.course.about}</Card.Text>
            <div className="set-bottom">
              {
                this.props.course.instructors.every(i => String(i.id) !== String(this.props.userId))
                && <button className="btn btn-sm btn-primary mr-2" onClick={this.handleParticipate}>+ Participate</button>
              }
              <span className="font-weight-light">{this.props.course.numOfParticipants} participants on leomango</span>
            </div>
          </Card.ImgOverlay>
        </Card>
        {/*----------------ALERTS*/}
        {
          this.state.participated &&
          <div className="alert alert-dismissible fade show alert-success mt-3" role="alert">
            Participated to the {this.props.course.name} course successfully!
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }
        {
          this.state.alreadyParticipated &&
          <div className="alert alert-dismissible fade show alert-secondary mt-3" role="alert">
            You are already participated to the {this.props.course.name} course.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }
        {
          this.state.login &&
          <div className="alert alert-dismissible fade show alert-danger mt-3" role="alert">
            You are not signed in! Please sign in using your Google account <a href={keys.apiURL + '/auth/google'}>here</a>.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }
        {/*----------------ALERTS*/}
      </div>
    )
  }
}

export default CourseHeader;
