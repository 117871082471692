import React from 'react';

function LoadigProfileColComponent() {
  return (
    <div className="board">
      Loading...
    </div>
  )
}

export default LoadigProfileColComponent;
