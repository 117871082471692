import React from 'react';
import axios from 'axios';
import keys from '../../keys';

import Loading from '../LoadingComponent';

class CoursesList extends React.Component {
  state = {
    userName: this.props.userName,
    courses: [],
    deletedCourses: [],
    notFound: true
  }

  componentDidMount() {
    this.getCoursesData();
  }

  getCoursesData = () => {
    axios.get(keys.apiURL + '/users/' + this.props.userName + '/courses').then(res => {
      this.setState({
        ...this.state,
        notFound: false,
        courses: res.data
      });
    }).catch(() => {
      this.setState({
        ...this.state,
        notFound: true
      });
    });
  }

  removeCourse = (courseId, courseName) => {
    axios.delete(keys.apiURL + '/courses/' + courseId, {withCredentials: true}).then(res => {
      if(res.data === 'deleted'){
        this.setState(prevState => ({
          ...this.state,
          deletedCourses: [...prevState.deletedCourses, courseName]
        }));
      }
    });
  }

  render() {
    if(this.state.status === 'deleted')
      setTimeout(() => window.location.reload(), 1500);

    let Component = <Loading />
    if(!this.state.notFound) {
      Component = (
        this.state.courses.map(course => {
          if(course.state !== 'deleted') {
            return (
              <li key={'rmb' + course.id} className="d-flex justify-content-between px-3">
                <span>{course.name}</span>
                <button className="btn btn-light" onClick={() => this.removeCourse(course.id, course.name)}>
                  <svg width="0.8rem" height="0.8rem" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </button>
              </li>
            );
          }
          return (
            <li key={'rmb' + course.id} className="d-flex justify-content-between px-3">
              <span><del>{course.name}</del></span>
              <span className="text-danger">deleted</span>
            </li>
          );
        })
      );
    }
    // deleted courses alerts
    const Alerts = this.state.deletedCourses.map(courseName => {
      return (
        <div key={Math.random()} className="alert alert-dismissible fade show alert-secondary" role="alert">
          { courseName + ' course deleted successfully.' }
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    });
    return (
      <div>
        { this.state.courses.length !== 0 &&
          <div>
            <h5 className="mt-2">Current courses</h5>
            <hr />
            { Component }
            <div className="p-3">{ Alerts }</div>
          </div>
        }

      </div>
    );
  }
}

export default CoursesList;
