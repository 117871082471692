import React from 'react';
import axios from 'axios';
import keys from '../../keys';

class ProfilePanelComponent extends React.Component {
  state = {
    user: {
      uName: this.props.user.info.name,
      uUserName: this.props.user.info.userName,
      uPicture: this.props.user.info.profilePicture
    },
    status: ''
  }
  handleChange = (e) => {
    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        [e.target.id]: e.target.value
      }
    });
  }
  handleSubmit = (e) => {
    this.setState({
      ...this.state,
      status: 'loading'
    });
    e.preventDefault();
    axios.post(keys.apiURL + '/users/edit', {user: this.state.user}, {withCredentials: true}).then(res => {
      if(res.status === 200 && res.data === 'edited') {
        this.setState({
          ...this.state,
          status: 'edited'
        });
      }
      else {
        this.setState({
          ...this.state,
          status: 'failed'
        });
      }
    });
  }
  render() {
    if(this.state.status === 'edited' || this.state.status === 'failed')
      setTimeout(() => window.location.reload(), 1500);

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="uUserName">Username</label>
            <input type="text" className="form-control" id="uUserName" onChange={this.handleChange} value={this.state.user.uUserName} />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="uName">Name</label>
            <input type="text" className="form-control" id="uName" onChange={this.handleChange} value={this.state.user.uName} />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="uPicture">Profile picture</label>
          <input type="text" className="form-control" id="uPicture" onChange={this.handleChange} value={this.state.user.uPicture} placeholder="image address goes here" />
        </div>
        <button type="submit" className="btn btn-primary">save changes</button>
          {
            this.state.status === 'loading' &&
            <p className="mt-3">
              please wait...
            </p>
          }
          {
            this.state.status === 'edited' &&
            <div className="alert alert-dismissible fade show alert-success mt-3" role="alert">
              Changes saved successfully!
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          }
          {
            this.state.status === 'failed' &&
            <div className="alert alert-dismissible fade show alert-danger mt-3" role="alert">
              Ops! something wrong happened.
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          }
      </form>
    );
  }
}

export default ProfilePanelComponent;
