import React from 'react';

import SectionBodyComponent from './SectionBodyComponent';
import EditButtonComponent from './EditButtonComponent';

function SectionsComponent(props) {
  const userId = props.user._id;
  let isInstructor = !props.course.instructors.every(i => (i.id !== userId));
  const list = props.course.sections.map((section, i) => {
    return (
      <div key={section._id} className="sections card mb-2">
        <button className="btn btn-link list-group-item list-group-item-action" data-toggle="collapse" data-target={'#a' + String(i*100)} aria-expanded="true" aria-controls="collapseOne">
          <h6 className="mb-0 text-center">
            { section.name }
          </h6>
        </button>
        <div id={'a' + String(i*100)} className="collapse" aria-labelledby={i} data-parent="#accordion">
          <div className="card-body py-3">
            {
              isInstructor && <EditButtonComponent section={section} />
            }
            <p>{ section.intro }</p>
            <hr />
            <SectionBodyComponent body={section.body} />
          </div>
        </div>
      </div>
    );
  });
  return(
    <div className="col-md-7" id="accordion">
      { list }
    </div>
  );
}

export default SectionsComponent;
