import React from 'react';
import YouTube from 'react-youtube';

import classifyURL from './classifyURL';
import driveImg from './Google-Drive-icon.png';

function SectionBodyComponent(props) {
  const body = props.body.map(part => {
    const info = classifyURL(part.url);
    if(info.class === 'youtube') {
      return (
        <div className="mb-4">
          <p className="mb-2">{part.paragraph}</p>
          <div className="video-box">
            <YouTube videoId={info.id} className="video" />
          </div>
        </div>
      );
    }
    if(info.class === 'googleDrive') {
      return (
        <div className="mb-4">
          <a href={ part.url } target="_blank">
            <img className="mr-1 drive-img" src={driveImg} alt="Google-Drive" />
            { part.paragraph }
          </a>
        </div>
      );
    }
    if(info.class === 'url') {
      return (
        <div className="mb-4">
          <a href={ (part.url.includes('http') ? '' : '//') + part.url } target="_blank">{ part.paragraph }</a>
        </div>
      );
    }
    // unclassified
    return (
      <div>
        <p>{part.paragraph}</p>
        <spam>{part.url}</spam>
      </div>
    );
  });
  return(
    <div>
      {body}
    </div>
  );
}

export default SectionBodyComponent;
