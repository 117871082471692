import React from 'react';
import axios from 'axios';
import keys from '../../keys';

import Loading from '../LoadingComponent';
import NotFound from '../NotFoundComponent';
import CourseHeader from './CourseComponent/CourseHeader';
import Panel from './PanelComponent';
import Sections from './CourseComponent/SectionsComponent/SectionsComponent';

class CoursePageComponent extends React.Component {
  state = {
    visitedCourse: {},
  }
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    axios.get(keys.apiURL + '/courses/' + this.props.routeProps.match.params.id).then(res => {
      this.setState({
        ...this.state,
        notFound: false,
        visitedCourse: res.data
      });
    }).catch(() => {
      this.setState({
        ...this.state,
        notFound: true
      });
    });
  }

  render() {
    let visitedCourse = this.state.visitedCourse;
    let Component = <Loading />
    if(this.state.notFound) {
      Component = <NotFound />
    }
    else if(Object.keys(visitedCourse).length !== 0) {
      Component = (
        <div className="container">
          <div>
            <CourseHeader userId={this.props.user.info._id} courseId={this.props.routeProps.match.params.id} course={this.state.visitedCourse} />
            {
              // checks if the visited user is an admin if yes it will show the panel
              !this.state.visitedCourse.instructors.every(i => String(i.id) !== String(this.props.user.info._id))
              && <Panel courseId={this.state.visitedCourse._id} user={this.props.user.info} />
            }
            <div className="row">
              <Sections course={this.state.visitedCourse} user={this.props.user.info} />
              <div className="col-md-5">
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        { Component }
      </div>
    );
  }
}

export default CoursePageComponent;
